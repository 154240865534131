<script>
import { SuawMainContent, SuawParagraph, SuawHeading, SuawBlockQuote } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "FiveTraitsOfAnUnforgettableScene",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    SuawBlockQuote,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FiveTraitsOfAnUnforgettableScene">
      <SuawParagraph
        text="What is the most unforgettable scene you’ve ever read? Did the vivid imagery capture your imagination, the hero’s dark hair flying behind them as they ran towards their hopeless goal?"
      />
      <SuawParagraph
        text="Whether you’re writing novels, short stories, or even flash fiction, your piece will consist of one or more scenes—the building block of most traditional forms of fiction. Mastering the art of scene-writing is therefore critical to maintaining your reader’s attention throughout your work. Here are five traits of an unforgettable scene to keep your reader hooked:"
      />
      <SuawHeading level="3" content="1. Characters Doing Things" />
      <SuawParagraph
        text="But what is a scene, anyway? As Sandra Scofield puts it in her excellent work, <em>The Scene Book: A Primer for the Fiction Writer</em>, a scene is “those passages in narrative when we slow down and focus on an event in the story so that we are ‘in the moment’ with characters in action.”"
      />
      <SuawParagraph
        text="“Action” and “characters” are the operative words here. To say it plainly, scenes involve characters doing things. The best scenes serve multiple purposes: building your plot and developing your characters. A reader should come away from your scene both hooked into your story and eager to know what’s next as a whole, as well as increasingly invested in the particular fates of your characters."
      />
      <SuawHeading level="3" content="2. An Exciting Trigger" />
      <SuawParagraph
        text="In ordinary life, we experience many mundane events: waking up groggily to our alarms, eating a subpar breakfast, dragging the kids to school, driving to work. Of course, not all of these moments are capable of holding the interest of a listener. If, for example, you were talking to a friend about how your day went, you probably wouldn’t involve them in a discussion of how you specifically brushed your teeth that day. Not unless something strange or interesting happened as you were brushing your teeth, like, say, your reflection jumping out at you from the mirror and standing dripping wet beside you on the bathroom floor. Now <em>that</em> is something your friend would want to know."
      />
      <SuawParagraph
        text="A good general rule of thumb is to use the interest of an imaginary audience member as a barometer of which events to recount in your scene. What happens to your characters that your imaginary reader <em>must</em> know?"
      />
      <SuawParagraph
        text="All scenes consist of events that set off a chain of reactions, which in turn snowball into more action. Your characters must face one or more triggers in their environment which cause one or more of them to say or do something. Controlling the sequence in which your characters react is of utmost importance when building a scene. You want the scene to flow in a certain way to create suspense, romance, awkwardness, or whatever mood you’re after."
      />
      <SuawHeading level="3" content="3. A Look Inside Your Character’s Head" />
      <SuawParagraph
        text="Make sure to occasionally step into your point of view character’s head to help your reader tune into their changing thoughts, feelings, and motivations. Some writers lean more heavily on the device of the internal monologue, whereas others, <a href='https://www.newyorker.com/magazine/1998/11/09/last-words-6' target='_blank'>like the famous Ernest Hemingway</a>, prefer to let external events reveal the mood and motivations of their protagonist. As an author, you will find your own unique balance with time. But keep in mind that your reader will want to know how the events in your scene are affecting your protagonist, so do your best to include glimpses of your point of view character’s inner world during the action."
      />
      <SuawParagraph
        text="The expression “show, don’t tell” highlights another essential element of developing your scene. Make use of gestures and expressions to immerse your reader in the action. For example, instead of saying, “Linda was angry that her neighbor kept riding his bicycle across her lawn,” you might instead consider, “Linda’s eyes narrowed as her neighbor, Ted, rode his bicycle across her lawn for the umpteenth time.” The narrowing of Linda’s eyes gives your reader a specific visual that helps them to identify her mood without being told what it is. This helps your reader connect more immediately with the scene because they’re able to directly observe how Linda’s face is changing and to interpret what that means about her emotional state."
      />
      <SuawHeading level="3" content="4. Meaningful Change In Your Characters" />
      <SuawParagraph
        text="As your plot unfolds, your characters, especially your protagonist, have to be dynamic. In earlier scenes, you may choose to focus on demonstrating your main character’s profile in a way that conveys their traits and typical reactions to events. For example, Jen is a somewhat disorganized individual who forgets to do basic things like put on deodorant before stepping out of her shabby apartment into a hot summer day."
      />
      <SuawParagraph
        text="But in later scenes, your protagonist should surprise your reader by demonstrating growth of some kind. Jen could, for instance, raise her hand at the staff meeting to step up and take the lead on an important new business initiative. Build your character’s actions, gestures, and interiority in line with the goals you have for your specific scene. Some authors elect to write down the goals they have for each individual scene before they draft the actual scene, whereas others prefer to draft the scene first as a way to uncover that scene’s goals."
      />
      <SuawHeading level="3" content="5. Interesting Settings" />
      <SuawParagraph
        text="Though characters and action are the two must-haves of any scene, another literary element you’re likely to use is <a href='/articles/five-ways-to-create-new-settings-in-your-writing/'>setting</a>—after all, your character’s actions have to happen <em>somewhere</em>, whether that be a city, a forest, someone’s house, or outer space."
      />
      <SuawParagraph text="You can start your scene by situating your characters in the setting of your choice. For example:" />
      <SuawBlockQuote text="It was a sultry Boston morning and Jen was already cursing herself for forgetting to put on deodorant before getting on the T to work." />
      <SuawParagraph text="Alternately, you can layer in setting after your characters have already begun saying and doing things. For example:" />
      <SuawBlockQuote
        text="After a year of keeping to himself, Garth’s first words to another human were, ‘I like your smile.’ ‘I like your smile, too!’ the woman responded, surprising him
          by returning the compliment. Before he could reply, she’d already turned around and was walking out of the warm coffee shop into the frigid Maine winter."
      />
      <SuawParagraph
        text="Every scene has one or more characters doing things and facing consequences. Setting can be an important part of scenes and help create that immersive feeling for your reader. As a writer, you have the option of listing your goals for a scene before beginning to draft it so that you can keep track of what you want to achieve. Finally, reading your scene out loud will help you identify areas where your scene could flow better, whether that’s ironing out dialogue or layering in a few more sentences that create a stronger sense of place."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
